//a, a:visited, a:hover, a:active {
//    color: white;
//    text-decoration: none;
//}

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased; /* This needs to be set or some font faced fonts look bold on Mac in Chrome/Webkit based browsers. */
    -moz-osx-font-smoothing: grayscale; /* Fixes font bold issue in Firefox version 25+ on Mac */
}

body {
    line-height: normal;
}

.react-autosuggest__container {
    position: relative;
    max-width: 800px;
    width: 100%;
}

//.react-autosuggest__input {
//  padding: 10px 20px;
//  //font-family: Helvetica, sans-serif;
//  //font-weight: 300;
//  font-size: 16px;
//  //border: 1px solid #aaa;
//  //border-radius: 4px;
//
//  color: rgba(0, 0, 0, 0.87);
//  background-color: rgb(255, 255, 255);
//  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
//  box-sizing: border-box;
//  font-family: Roboto, sans-serif;
//  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
//  // border-radius: 2px;
//  border: 0;
//  height: 48px;
//  width: 100%;
//  display: flex;
//  justify-content: space-between;
//  margin: 0px auto;
//}

.react-autosuggest__input {}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
